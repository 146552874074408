import {AI_CHATBOT_LINK} from './global';
export const COOKIE_TITLE = 'Cookie Policy';
export const AI_CHATBOT_LINK_TITLE = 'PrescriberAI';

const FOOTER_LINKS = [
  {
    title: 'Providers',
    links: [
      {
        title: 'Sign Up / Sign In',
        path: '',
      },
      {
        title: 'Log in',
        path: 'login',
      },
      {
        title: 'Sign up',
        path: 'signup',
      },
      {
        title: 'Request demo',
        path: 'request-demo',
      },
    ],
  },
  {
    title: 'Solutions',
    links: [
      {
        title: 'Prescribing Information',
        path: 'prescribing-information',
      },
      {
        title: 'Coverage Restrictions',
        path: 'coverage-restrictions',
      },
      {
        title: 'Prior Authorization',
        path: 'prior-authorizations',
      },
      {
        title: 'Financial Assistance',
        path: 'financial-assistance',
      },
      {
        title: 'Practice Collaboration',
        path: 'practice-collaboration',
      },
      {
        title: 'Rep Management',
        path: 'rep-access',
      },
      {
        title: AI_CHATBOT_LINK_TITLE,
        href: AI_CHATBOT_LINK,
      },
    ],
  },
  {
    title: 'Indications',
    links: [
      {
        title: 'Most Viewed',
        path: 'most-viewed',
      },
      {
        title: 'Angioedema',
        path: 'indications/angioedema',
      },
      {
        title: 'Atopic Dermatitis',
        path: 'indications/atopic-dermatitis',
      },
      {
        title: 'Plaque Psoriasis',
        path: 'indications/psoriasis',
      },
      {
        title: 'Psoriatic Arthritis',
        path: 'indications/psoriatic-arthritis',
      },
      {
        title: 'Rosacea',
        path: 'indications/rosacea',
      },
      {
        title: 'Compare drug alternatives',
        path: 'compare',
      },
    ],
  },
  {
    title: 'Company',
    links: [
      {
        title: 'About',
        path: 'about',
      },
      {
        title: 'Careers',
        href: 'https://prescriberpoint.hrmdirect.com/employment/job-openings.php?search=true&',
      },
      /*{ We don't have this page yet
        title: 'Press',
        path: 'press',
      },*/
      {
        title: 'Terms & Conditions',
        path: 'terms',
      },
      {
        title: 'Privacy Policy',
        path: 'privacy',
      },
      {
        title: COOKIE_TITLE,
        path: 'cookie',
      },
      {
        title: 'Contact us',
        path: 'contact',
      },
    ],
  },
];

export default FOOTER_LINKS;
