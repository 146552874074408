import {FC, useEffect, useState} from 'react';
import {MED_AI_API_URL} from '@/constants/global';

export interface IMedAIChatbotProps {
  drugName?: string;
  genericName?: string;
  isModalOnMobile?: boolean;
  triggerId?: string;
  setId?: string;
}

const MedAIChatbot: FC<IMedAIChatbotProps> = ({
  drugName,
  genericName,
  isModalOnMobile = true,
  triggerId = '',
  setId,
}) => {
  const [component, setComponent] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const existingWebComponent = document.querySelector('prescriber-ai-wc');
    if (existingWebComponent && drugName && genericName) {
      // If the Web Component already exists, we set the attributes directly
      existingWebComponent.setAttribute('drug-name', drugName);
      existingWebComponent.setAttribute('generic-name', genericName);
      return;
    }

    const webComponent = document.createElement('prescriber-ai-wc');

    if (drugName && genericName && setId) {
      webComponent.setAttribute('drug-name', drugName);
      webComponent.setAttribute('generic-name', genericName);
      webComponent.setAttribute('set-id', setId);
    }
    webComponent.setAttribute('is-modal-on-mobile', isModalOnMobile.toString());
    webComponent.setAttribute('trigger-id', triggerId);
    webComponent.setAttribute('fixed-height', 'false');
    webComponent.setAttribute('ai-professor-api-base-url', MED_AI_API_URL);
    webComponent.setAttribute('cdn-folder', 'widgets');

    setComponent(webComponent);
  }, [drugName, genericName, isModalOnMobile, setId, triggerId]);

  return (
    <div className='md:py-12'>
      <div id='web-component-container' className='relative'>
        {component ? (
          <div
            className='md:h-[510px]'
            dangerouslySetInnerHTML={{__html: component.outerHTML}}
          />
        ) : null}
      </div>
    </div>
  );
};

export default MedAIChatbot;
