import {algoliasearch} from 'algoliasearch';
import {
  ALGOLIA_APP_ID,
  ALGOLIA_SEARCH_API_KEY,
  DEV_ALGOLIA_APP_ID,
  DEV_ALGOLIA_SEARCH_API_KEY,
} from '@/constants/global';

export const searchClient = algoliasearch(
  ALGOLIA_APP_ID,
  ALGOLIA_SEARCH_API_KEY,
);

export const devClient = algoliasearch(
  DEV_ALGOLIA_APP_ID,
  DEV_ALGOLIA_SEARCH_API_KEY,
);
