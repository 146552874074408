import {useCallback, useMemo} from 'react';
import {REQUEST_SAMPLES_SECTION} from '@/constants/flags';
import {
  SECTION_IDS as FULL_IDS,
  OTHER_SECTION_IDS,
} from '@/constants/sectionIDs';
import {useSectionsStatus} from '@/hooks/showSections/useSectionsStatus';
import {useShowEnhancedSection} from '@/hooks/showSections/useShowEnhancedSection';
import {useRenderInIFrame} from '@/hooks/useRenderInIFrame';
import {useSectionIds} from '@/hooks/useSectionIds';
import {useShowContentSummaries} from '@/hooks/useShowContentSummaries';
import {getSectionTitleFromId} from '@/modules/ProductDetailPage/utils';

const useFilteredAnchors = (setId: string) => {
  const pdpIsEmbedded = useRenderInIFrame();
  const {
    showFASectionLinks,
    showPE,
    showPA,
    showDosage,
    showFAQS,
    showPubMed,
    showLabel,
    showCoverageInSidebar,
  } = useSectionsStatus();
  const {anchors, sectionIds} = useSectionIds();
  const showContentSummaries = useShowContentSummaries(setId);
  const showRS = useShowEnhancedSection({
    sectionId: FULL_IDS.requestSamples,
    flagName: REQUEST_SAMPLES_SECTION,
    checkAvailableResources: true,
  });

  const getSectionTitle = useCallback(
    (id: string) => {
      if (
        id === sectionIds.summary ||
        id === OTHER_SECTION_IDS.dosingAndAdmiministration
      ) {
        return showContentSummaries
          ? 'Quick Reference'
          : 'Dosage & Administration';
      }

      if (id === sectionIds.pubMed) {
        return 'PubMed™ News';
      }

      if (id === sectionIds.coverage) {
        return 'Coverage';
      }

      if (id === sectionIds.peopleAlsoAsk) {
        return 'FAQ';
      }

      return getSectionTitleFromId(id);
    },
    [sectionIds, showContentSummaries],
  );
  const filteredAnchors = useMemo(() => {
    const sectionFilters = {
      'Dosage & Administration': !pdpIsEmbedded && showDosage,
      'Drug label': showLabel,
      Samples: !pdpIsEmbedded && showRS,
      'Prior authorization': showPA,
      'Financial assistance': showFASectionLinks,
      FAQ: showFAQS,
      'PubMed™ News': showPubMed,
      'Patient education': showPE,
      Coverage: showCoverageInSidebar,
    };

    return anchors.filter((item) => {
      const sectionTitle = getSectionTitle(item);
      if (!(sectionTitle in sectionFilters)) {
        return true;
      }
      return sectionFilters[sectionTitle as keyof typeof sectionFilters];
    });
  }, [
    pdpIsEmbedded,
    showDosage,
    showLabel,
    showRS,
    showPA,
    showFASectionLinks,
    showFAQS,
    showPubMed,
    showPE,
    showCoverageInSidebar,
    anchors,
    getSectionTitle,
  ]);

  return {filteredAnchors, getSectionTitle};
};

export default useFilteredAnchors;
