import {FC, memo} from 'react';
import Content from './Content';
import {CoverageRestrictionsProvider} from '@/context';
import {useSectionsStatus} from '@/hooks/showSections/useSectionsStatus';

interface PdpLayoutNewProps {
  children: React.ReactNode;
}

const PdpLayoutNew: FC<PdpLayoutNewProps> = ({children}) => {
  const {showCoverageOnPdp} = useSectionsStatus();

  return showCoverageOnPdp ? (
    <CoverageRestrictionsProvider>
      <Content>{children}</Content>
    </CoverageRestrictionsProvider>
  ) : (
    <Content>{children}</Content>
  );
};

export default memo(PdpLayoutNew);
