import {Logo} from '@prescriberpoint/ui';
import Link from 'next/link';
import {FC} from 'react';

interface AuthNavigationProps {}

const AuthNavigation: FC<AuthNavigationProps> = () => (
  <div className="flex w-full h-16 items-center justify-center bg-white py-3 sm:shadow-sm md:justify-start md:px-16 md:relative md:z-40">
    <Link
      href="/"
      className="flex justify-center h-8 [&>div]:w-fit [&>div]:h-fit md:justify-start">
      <Logo />
    </Link>
  </div>
);

export default AuthNavigation;
