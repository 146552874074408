import {Text} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {FC, ReactNode, useEffect, useLayoutEffect, useRef} from 'react';
import {CTA_SECTIONS_IDS} from '@/constants/ctaServices';
import {useShowFixedCTAMenu} from '@/hooks';
import {useRouter} from '@/hooks/useRouter';

const canUseDOM = typeof window !== 'undefined';
const useIsomorphicLayoutEffect = canUseDOM ? useLayoutEffect : useEffect;

interface ISectionHeaderProps {
  title?: string;
  headingCta?: ReactNode;
  subtitle?: string | ReactNode;
  className?: ClassValue;
}

const SectionHeader: FC<ISectionHeaderProps> = ({
  title,
  subtitle,
  className,
  headingCta,
}) => (
  <div className='flex flex-col gap-x-6 gap-y-3 sm:flex-row'>
    <div className={clsx('flex flex-1 flex-col gap-y-2', className)}>
      {title ? (
        <Text
          tag='h2'
          as='title-lg'
          weight='extrabold'
          className='tracking-[0.25px] md:font-houschka-pro md:text-3xl md:font-bold'>
          {title}
        </Text>
      ) : null}
      {subtitle ? (
        <Text tag='h4' as='title-md' weight='regular'>
          {subtitle}
        </Text>
      ) : null}
    </div>
    {headingCta ? <div className='flex items-center'>{headingCta}</div> : null}
  </div>
);

export interface ISectionProps {
  children: React.ReactNode;
  id: string;
  title?: string;
  headingCta?: ReactNode;
  subtitle?: string | ReactNode;
  className?: ClassValue;
  childrenContainerClassName?: string;
  headerClassName?: ClassValue;
}

const SectionNew: FC<ISectionProps> = ({
  id,
  title,
  subtitle,
  headingCta,
  className,
  headerClassName,
  childrenContainerClassName,
  children,
}) => {
  const router = useRouter();
  const ref = useRef<HTMLDivElement>(null);
  const showCTAFixedMenu = useShowFixedCTAMenu();

  useIsomorphicLayoutEffect(() => {
    if (router?.query?.help) {
      const sectionId = CTA_SECTIONS_IDS[router.query.help as string];
      const element = ref.current;
      if (element?.id === sectionId) {
        //to make sure all elemnts are loaded, otherwise section might be missplaced
        const timeout = setTimeout(() => {
          if (showCTAFixedMenu) {
            const anchor = router?.asPath?.split('#')[1];
            document.getElementById(`ss_pivot_${anchor}`)?.click();
          } else {
            element.scrollIntoView();
          }
        }, 500);
        return () => clearTimeout(timeout);
      }
    }
  }, [router]);

  return (
    <div
      ref={ref}
      id={id}
      data-testid={id}
      className={clsx(
        'scroll-mt-8 space-y-5 py-8 md:space-y-8 md:py-12',
        className,
      )}>
      {title ? (
        <SectionHeader
          title={title}
          subtitle={subtitle}
          headingCta={headingCta}
          className={headerClassName}
        />
      ) : null}
      <div className={childrenContainerClassName}>{children}</div>
    </div>
  );
};

export default SectionNew;
