import {Text} from '@prescriberpoint/ui';
import {FC, useCallback} from 'react';
import {LucideIconNameType} from '../Icon';
import CrossSellCta from './CrossSellCta';
import {SECTION_IDS} from '@/constants/sectionIDs';
import {useCurrentDrug} from '@/hooks';
import {useRouter} from '@/hooks/useRouter';

export interface CrossSellCtaList {
  sectionId: string;
  title: string;
  iconName?: LucideIconNameType;
}

export const DEFAULT_CTA_LIST: CrossSellCtaList[] = [
  {
    sectionId: 'samples',
    title: 'Get Samples',
    iconName: 'pill-bottle',
  },
  {
    sectionId: SECTION_IDS.coverage,
    title: 'Get Detailed Coverage Information',
    iconName: 'file-chart-pie',
  },
  {
    sectionId: SECTION_IDS.financialAssistance,
    title: 'Get Financial Assistance Program Details',
    iconName: 'arrow-down-up',
  },
  {
    sectionId: SECTION_IDS.priorAuthorization,
    title: 'Get Prior Authorization Forms',
    iconName: 'clipboard-plus',
  },
  {
    sectionId: SECTION_IDS.patientEducation,
    title: 'Get Patient Education Material',
    iconName: 'book-open-text',
  },
];

export interface ICrossSellBannerProps {
  title: string;
  ctas?: CrossSellCtaList[];
}

const CrossSellBanner: FC<ICrossSellBannerProps> = ({
  title,
  ctas = DEFAULT_CTA_LIST,
}) => {
  const {slug} = useCurrentDrug();
  const router = useRouter();

  const handleCtaClick = useCallback(
    (sectionId: string) => {
      const url = `/therapies/${slug}#${sectionId}`;
      if (sectionId === 'samples') {
        window.location.href = `/therapies/${slug}/samples`;
      } else {
        router.push(url);
      }
    },
    [slug, router],
  );

  return (
    <div className='flex w-full flex-col items-center justify-center gap-y-5 bg-opacity-25 bg-cross-selling-card via-gold-300 px-5 py-8 lg:gap-y-8 lg:px-6 lg:py-16'>
      <div className='text-center'>
        <Text as='headline-md'>{title}</Text>
      </div>
      <div className='flex w-full flex-col justify-center gap-4 lg:flex-row'>
        {ctas.map(({sectionId, title, iconName}) => (
          <CrossSellCta
            key={title}
            iconName={iconName}
            onClick={() => handleCtaClick(sectionId)}>
            {title}
          </CrossSellCta>
        ))}
      </div>
    </div>
  );
};

export default CrossSellBanner;
