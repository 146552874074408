import {FC} from 'react';
import {InstantSearch as ISearch, Configure} from 'react-instantsearch';
import {ALGOLIA_INDEX} from '@/constants/global';
import {searchClient} from '@/services/algoliaClients';

interface SearchInputProps {
  children: React.ReactNode;
  hitsPerPage: number;
  analitycs: boolean;
  filters?: string;
}

export const WrapperInstantSearch: FC<SearchInputProps> = ({
  hitsPerPage,
  analitycs,
  children,
  filters,
}) => (
  <ISearch indexName={ALGOLIA_INDEX} searchClient={searchClient}>
    <Configure
      hitsPerPage={hitsPerPage}
      analytics={analitycs}
      filters={filters}
      distinct
    />
    {children}
  </ISearch>
);
