'use client';
import clsx, {ClassValue} from 'clsx';
import dynamic from 'next/dynamic';
import {FC, useEffect, useState} from 'react';
import {CustomNavbar as Navbar, Footer} from '@/components';
import CrossSellBanner from '@/components/CrossSellBanner';
import Disclaimer from '@/components/Disclaimer';
import {NAVBAR_ID} from '@/constants';
import {TERMS_AND_CONDITIONS} from '@/constants/flags';
import {MobileContentProvider, useFlag} from '@/context';
import {useUserAgentContext} from '@/context/UserAgentContext';
import {useNhHideQueryParam} from '@/hooks';
import {useCurrentPerson} from '@/queries';

const TermsAndConditions = dynamic(
  () => import('@/modules/HomePage/TermsAndConditions'),
  {
    ssr: false,
  },
);

interface PageWithBreadcrumbLayoutProps {
  children: React.ReactNode;
  BreadCrumbComponent?: React.ReactElement<any>;
  className?: ClassValue;
  rootBackground?: string;
  landpage?: boolean;
  withCrossSellBanner?: boolean;
}

const PageWithBreadcrumbLayout: FC<PageWithBreadcrumbLayoutProps> = ({
  children,
  BreadCrumbComponent,
  className,
  rootBackground,
  landpage = false,
  withCrossSellBanner = false,
}) => {
  const {isMobileOrTablet} = useUserAgentContext();
  const termsAndConditionsEnabled = useFlag(TERMS_AND_CONDITIONS);
  const {data: user} = useCurrentPerson();
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const {shouldHide} = useNhHideQueryParam();
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const nhOn = shouldHide && landpage;
  const showBreadcrumb = !nhOn && !!BreadCrumbComponent;
  const showBanner = withCrossSellBanner && !shouldHide;
  const showFooter = !nhOn;

  useEffect(() => {
    if (termsAndConditionsEnabled && user)
      setShowTermsAndConditions(user.shouldNeedAcceptTsCs);
  }, [user, termsAndConditionsEnabled]);

  const closeTermsAndConditions = () => {
    setShowTermsAndConditions(false);
  };

  return (
    <MobileContentProvider>
      <div className='flex min-h-screen flex-col'>
        {showTermsAndConditions && (
          <TermsAndConditions handleClose={closeTermsAndConditions} />
        )}

        <div
          id={NAVBAR_ID}
          className={clsx([
            'relative w-full bg-white',
            isMobileOrTablet ? 'z-50' : 'z-max',
            {
              'sticky top-0 z-max': true,
            },
          ])}>
          <Disclaimer
            showDisclaimer={showDisclaimer}
            setShowDisclaimer={setShowDisclaimer}
          />
          <Navbar nhOn={nhOn} />
        </div>
        <div
          className={clsx('flex flex-1 flex-col pb-3 md:pb-6', {
            'pt-6': !showBreadcrumb,
          })}
          id='layout-content'>
          {showBreadcrumb ? (
            <div className={clsx('w-full pt-6 md:pt-4', rootBackground)}>
              <div
                className={clsx(
                  'h-full w-full max-w-[1440px] px-6 sm:block md:mx-auto md:px-4',
                  className,
                )}>
                {BreadCrumbComponent}
              </div>
            </div>
          ) : null}
          <div className={clsx('flex-1 md:px-4', rootBackground)}>
            {children}
          </div>
          {showBanner ? <CrossSellBanner title='Explore Our Tools' /> : null}
          {showFooter ? (
            <div className='w-full'>
              <div className='flex'>
                <Footer />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </MobileContentProvider>
  );
};

export default PageWithBreadcrumbLayout;
