'use client';
import {useIsSmallDesktop} from '@prescriberpoint/ui';
import {FC, memo} from 'react';
import {NEW_VERSION_DS} from '@/constants/flags';
import {MobileContentProvider, useUserAgentContext, useFlag} from '@/context';
import {useUser} from '@/hooks';
import {useRouter} from '@/hooks/useRouter';
import {UserPanelType} from '@/interfaces/userMenu';
import {PdpLayout, PdpLayoutNew} from '@/layouts';

interface PdpLayoutWrapperProps {
  children: React.ReactNode;
}

const PdpLayoutWrapper: FC<PdpLayoutWrapperProps> = ({children}) => {
  const router = useRouter();
  const {panel} = router.query;

  const {isMobileOrTablet} = useUserAgentContext();
  const isSmallDesktop = useIsSmallDesktop();
  const {logged} = useUser();
  const newVersionDS = useFlag(NEW_VERSION_DS);

  return (
    <MobileContentProvider
      userPanel={
        isMobileOrTablet || (!logged && isSmallDesktop)
          ? (panel as UserPanelType)
          : undefined
      }>
      {newVersionDS ? (
        <PdpLayoutNew>{children}</PdpLayoutNew>
      ) : (
        <PdpLayout>{children}</PdpLayout>
      )}
    </MobileContentProvider>
  );
};

export default memo(PdpLayoutWrapper);
