import {Button} from '@prescriberpoint/ui';
import Image from 'next/image';
import {FC, useCallback} from 'react';
import {useRouter} from '@/hooks/useRouter';

export interface SponsoredCardProps {
  src: string;
  subtitle: string;
  title: string;
  slug: string;
}

const SponsoredCard: FC<SponsoredCardProps> = ({
  src,
  subtitle,
  title,
  slug,
}) => {
  const router = useRouter();
  const handleClick = useCallback(
    () => router.push(`therapies/${slug}`),
    [router, slug],
  );

  return (
    <div className='flex-1 rounded-sm bg-white p-4'>
      <div className='mb-5 flex gap-4'>
        <Image src={src} alt={`${title}, ${subtitle}`} width='72' height='72' />
        <span className='text-base'>
          <small className='block text-xs text-neutral-secondary'>
            ({subtitle})
          </small>
          {title}
        </span>
      </div>
      <Button id={`view_details_${slug}`} onClick={handleClick} stretched>
        View details
      </Button>
    </div>
  );
};

export default SponsoredCard;
