import clsx from 'clsx';
import {FC, ReactNode} from 'react';
import * as FLAGS from '@/constants/flags';
import {useFlag} from '@/context';

interface ContentLayoutProps {
  children: ReactNode;
}

const PageContentLayout: FC<ContentLayoutProps> = ({children}) => {
  const showNewDesign = useFlag(FLAGS.SHOW_V2_PPT_DESIGN);

  return (
    <div
      className={clsx(
        'relative mx-auto max-w-[1440px] md:mb-4 md:px-2',
        showNewDesign
          ? 'bg-neutral-lighter-alt'
          : 'bg-neutral-white md:flex md:space-x-9',
      )}>
      {children}
    </div>
  );
};

export {default as PageContentLayoutNew} from './PageContentLayoutNew';
export default PageContentLayout;
