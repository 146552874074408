import {Icon, IconButton, Text} from '@prescriberpoint/ui';
import {useEffect} from 'react';
import {ONLY_HCPS_DISCLAIMER} from '@/constants/flags';
import {useFlag} from '@/context';
import useUserData from '@/hooks/useUserData';

interface DisclaimerProps {
  showDisclaimer: boolean;
  setShowDisclaimer: (_show: boolean) => void;
}

const Disclaimer = ({showDisclaimer, setShowDisclaimer}: DisclaimerProps) => {
  const {userData, setUserData} = useUserData();
  const onlyHcpsDisclamer = useFlag(ONLY_HCPS_DISCLAIMER);

  useEffect(() => {
    setShowDisclaimer(onlyHcpsDisclamer && userData.showDisclaimer);
  }, [onlyHcpsDisclamer, userData.showDisclaimer, setShowDisclaimer]);

  return showDisclaimer ? (
    <div
      className='z-50 flex h-12 w-full items-center justify-between bg-warning-fill md:justify-center'
      data-testid='disclaimer'>
      <div className='mt-2 min-w-6 self-start md:mt-0 md:self-auto'>
        <Icon
          name='InfoCircle'
          className='ml-3 mr-3 flex w-3 items-center text-neutral-secondary md:mr-1'
        />
      </div>
      <div className='md:max-width-5/6 w-auto'>
        <Text as='body-xs'>
          This website is for US healthcare professionals only. This website is
          not intended for patients.
        </Text>
      </div>
      <IconButton
        className='mx-3'
        size='sm'
        onClick={() =>
          setUserData((prevValue) => ({
            ...prevValue,
            showDisclaimer: false,
          }))
        }
        id='close_disclaimer_button'
        icon={<Icon name='Cancel' className='flex h-3 w-3 text-black' />}
      />
    </div>
  ) : null;
};

export default Disclaimer;
