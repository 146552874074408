import {useMutation} from '@tanstack/react-query';
import {create} from '@/services/api';

export interface IRepCheckin {
  locationId: string;
}

export async function repCheckin(params: IRepCheckin) {
  return await create(
    'CheckIn',
    {
      params,
    },
    {},
    'profile',
    true,
  );
}

export function useRepCheckin(options = {}) {
  return useMutation({mutationFn: repCheckin, ...options});
}
