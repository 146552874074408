import {FC} from 'react';
import MultiDrugSearchbarListInput from './components/MultiDrugSearchbarListInput';
import SelectedSearchResults from './components/SelectedSearchResults';
import {useUserAgentContext} from '@/context/UserAgentContext';
import {useWebkitOverScrollFix} from '@/hooks/useWebkitOverScrollFix';
import {WrapperInstantSearch} from '@/services/WrapperInstantSearch';

interface SearchbarListProps {}
const SearchbarList: FC<SearchbarListProps> = () => {
  const {isMobile, isTablet} = useUserAgentContext();

  useWebkitOverScrollFix(isMobile || isTablet);

  return (
    <WrapperInstantSearch hitsPerPage={5} analitycs={false}>
      <div className='flex flex-col px-4 pb-4'>
        <MultiDrugSearchbarListInput />
        <SelectedSearchResults />
      </div>
    </WrapperInstantSearch>
  );
};

export default SearchbarList;
