import {useCallback, useEffect, useRef, useState} from 'react';
import useFilteredAnchors from './useFilteredAnchors';
import {SHOW_V2_PPT_DESIGN} from '@/constants/flags';
import {
  SIDEBAR_PIVOT_OFFSET_SCROLL,
  OFFSET_SCROLL_WITH_PDP_HEADER_V2,
} from '@/constants/pdpPage';
import {SUBPAGES_SECTION_IDS} from '@/constants/sectionIDs';
import {useFlag} from '@/context/FlagsContext';
import {useLoadingContext} from '@/context/LoadingContext';
import {useMobileContentContext} from '@/context/MobileContentProvider';
import {useUserAgentContext} from '@/context/UserAgentContext';
import useIsPDPSubpage from '@/hooks/useIsPDPSubpage';
import {useShowFixedCTAMenu} from '@/hooks/useShowFixedCTAMenu';
import {isElementVisible} from '@/utils/ui';

interface ScrollHandlerParams {
  filteredAnchors: string[];
  checkIfPdpHeaderV1IsVisible: () => void;
  hasScrolledToTop: () => boolean;
  hasScrolledToBottom: () => boolean;
  isElementVisible: (
    _rect: DOMRect,
    _viewportOffset: number,
    _extraOffset?: number,
  ) => boolean;
  viewportOffset: number;
  showCTAFixedMenu: boolean;
  isIntercomOpened: () => boolean;
  setActiveItem: (_item: string) => void;
}

const handleScrollLogic = ({
  filteredAnchors,
  checkIfPdpHeaderV1IsVisible,
  hasScrolledToTop,
  hasScrolledToBottom,
  isElementVisible,
  viewportOffset,
  showCTAFixedMenu,
  isIntercomOpened,
  setActiveItem,
}: ScrollHandlerParams) => {
  if (showCTAFixedMenu) {
    checkIfPdpHeaderV1IsVisible();
  }

  if (isIntercomOpened()) {
    setActiveItem('');
    const pivotEl = document.querySelector('#pivotMenu');
    pivotEl?.scrollTo({left: 0, behavior: 'smooth'});
    return;
  }

  if (hasScrolledToBottom()) {
    setActiveItem(filteredAnchors[filteredAnchors.length - 1]);
    return;
  }

  if (hasScrolledToTop()) {
    setActiveItem('');
    return;
  }

  const visibleItem = filteredAnchors.find((anchor) => {
    const el = document.querySelector(`#${anchor}`);
    if (!el) return false;
    return isElementVisible(el.getBoundingClientRect(), viewportOffset, 30);
  });

  if (visibleItem) {
    setActiveItem(visibleItem);
  }
};

const checkPdpHeaderVisible = (
  newDesign: boolean,
  viewportOffset: number,
  setShowCta: (_show: boolean) => void,
) => {
  if (newDesign) {
    const pdpHeaderV1 = document.querySelector('#pdp-header-v1');
    const isPdpHeaderV1Visible = pdpHeaderV1
      ? isElementVisible(pdpHeaderV1.getBoundingClientRect(), viewportOffset)
      : false;

    setShowCta(isPdpHeaderV1Visible);
  }
};

const useScrollHandler = (
  setId: string,
  section: keyof typeof SUBPAGES_SECTION_IDS,
) => {
  const showCTAFixedMenu = useShowFixedCTAMenu();
  const showNewDesign = useFlag(SHOW_V2_PPT_DESIGN);
  const {setShowCTAMenu} = useMobileContentContext();
  const {isMobileOrTablet} = useUserAgentContext();
  const [activeItem, setActiveItem] = useState('');
  const {filteredAnchors} = useFilteredAnchors(setId);
  const viewportOffset = isMobileOrTablet ? 56 : 64;
  const muteScrolling = useRef(false);
  const debouncedScrollAnimationRef = useRef<number | null>(null);
  const isPDPSubpage = useIsPDPSubpage();
  const {isLoading} = useLoadingContext();

  const isIntercomOpened = () => {
    const hasIntercomOpened = document
      .getElementsByTagName('html')[0]
      .classList.contains('intercom-mobile-messenger-active');

    return hasIntercomOpened;
  };

  const checkIfPdpHeaderV1IsVisible = useCallback(
    () => checkPdpHeaderVisible(showNewDesign, viewportOffset, setShowCTAMenu),
    [setShowCTAMenu, showNewDesign, viewportOffset],
  );

  const hasScrolledToTop = useCallback(() => {
    const firstAnchor = filteredAnchors[0];
    const el = document.querySelector(`#${firstAnchor}`);

    if (!el) {
      return false;
    }

    return (
      el.getBoundingClientRect().top >
      (showCTAFixedMenu
        ? OFFSET_SCROLL_WITH_PDP_HEADER_V2
        : SIDEBAR_PIVOT_OFFSET_SCROLL)
    );
  }, [filteredAnchors, showCTAFixedMenu]);

  const hasScrolledToBottom = () => {
    const pdpWrapper = document.scrollingElement!;

    const hasScrolledToBottomVal =
      Math.floor(pdpWrapper.scrollHeight - pdpWrapper.scrollTop) ===
      pdpWrapper.clientHeight;

    return hasScrolledToBottomVal;
  };

  const scrollHandler = useCallback(() => {
    if (!(muteScrolling.current || isPDPSubpage)) {
      handleScrollLogic({
        filteredAnchors,
        checkIfPdpHeaderV1IsVisible,
        hasScrolledToTop,
        hasScrolledToBottom,
        isElementVisible,
        viewportOffset,
        showCTAFixedMenu,
        isIntercomOpened,
        setActiveItem,
      });
    }
  }, [
    filteredAnchors,
    isPDPSubpage,
    checkIfPdpHeaderV1IsVisible,
    hasScrolledToTop,
    showCTAFixedMenu,
    viewportOffset,
  ]);

  useEffect(() => {
    const debouncedScrollHandler = () => {
      if (debouncedScrollAnimationRef.current) {
        cancelAnimationFrame(debouncedScrollAnimationRef.current);
      }

      debouncedScrollAnimationRef.current = requestAnimationFrame(() => {
        scrollHandler();
      });
    };
    window.addEventListener('scroll', debouncedScrollHandler, true);
    return () => window.removeEventListener('scroll', debouncedScrollHandler);
  }, [scrollHandler]);

  useEffect(() => {
    if (isPDPSubpage) {
      const currentSection = SUBPAGES_SECTION_IDS[section];
      setActiveItem(currentSection);
    }
  }, [isPDPSubpage, section]);

  useEffect(() => {
    const pdpWrapper = document.scrollingElement!;
    if (isLoading) {
      pdpWrapper.scrollTo({
        top: 0,
      });
      setActiveItem(filteredAnchors[-1]);
    }
  }, [isLoading, filteredAnchors]);

  return {activeItem, setActiveItem, checkIfPdpHeaderV1IsVisible};
};

export default useScrollHandler;
