'use client';
import clsx from 'clsx';
import {FC, memo} from 'react';
import AuthNavigation from '@/components/Navbar/AuthNavigation';
import {useUserAgentContext} from '@/context/UserAgentContext';
import Features from '@/modules/SignupPage/Features';

interface IRepAuthLayoutProps {
  children: React.ReactNode;
  relativeContent?: boolean;
}

const RepAuthLayout: FC<IRepAuthLayoutProps> = ({
  children,
  relativeContent = true,
}) => {
  const {isMobileOrTablet, userAgent} = useUserAgentContext();

  return (
    <div
      className={clsx(
        'flex min-h-screen flex-col bg-white',
        userAgent?.isIosOrSafari ? 'min-h-available-space' : 'min-h-screen',
      )}>
      <AuthNavigation />
      <div className='flex flex-1 flex-col md:flex-row'>
        <div
          className={clsx(
            'w-full flex-1 md:w-[560px] md:flex-none',
            relativeContent ? 'relative' : null,
          )}>
          {children}
        </div>
        {!isMobileOrTablet && (
          <div className='flex flex-1 flex-col items-start justify-start bg-neutral-dark p-8'>
            <Features isRepSignup />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(RepAuthLayout);
