import {useRouter} from 'next/compat/router';

export const isPDPSubpage = (url?: string) => {
  if (!url) return false;
  const regex =
    /^\/therapies\/[^/]+\/(label|patient-resources|prior-authorizations|financial-assistance)(#.*)?$/;

  return regex.test(url);
};

function useIsPDPSubpage() {
  const router = useRouter();
  if (router) {
    return isPDPSubpage(router.asPath);
  } else if (typeof window !== 'undefined') {
    const path = window.location.pathname;
    return isPDPSubpage(path);
  }
  return false;
}

export default useIsPDPSubpage;
