import clsx from 'clsx';
import {FC} from 'react';
import {Footer} from '@/components';
import {useUserAgentContext} from '@/context/UserAgentContext';
import {useSectionsStatus} from '@/hooks/showSections/useSectionsStatus';
import {useRenderInIFrame} from '@/hooks/useRenderInIFrame';
import useUser from '@/hooks/useUser';

const Bottom: FC = () => {
  const {isMobileOrTablet} = useUserAgentContext();
  const {logged} = useUser();
  const {showButtonAI} = useSectionsStatus();

  const pdpIsEmbedded = useRenderInIFrame();

  if (pdpIsEmbedded) return null;

  return (
    <div className='w-full'>
      {!logged ? <Footer /> : null}
      <div
        className={clsx('bg-neutral-dark md:hidden', {
          'min-h-[87px]': showButtonAI && isMobileOrTablet,
        })}
      />
    </div>
  );
};

export default Bottom;
