import {useCallback, useEffect, useRef} from 'react';
import {useCookies} from 'react-cookie';
import useFilteredAnchors from './useFilteredAnchors';
import useScrollHandler from './useScrollHandler';
import {CEVENT_NAVIGATE, DlvNavigate} from '@/constants';
import {SPLIT_USER_COOKIE} from '@/constants/cookies';
import * as FLAGS from '@/constants/flags';
import {
  ENHANCED_SECTION_IDS,
  SUBPAGES_SECTION_IDS,
} from '@/constants/sectionIDs';
import {useFlag} from '@/context/FlagsContext';
import {useUserAgentContext} from '@/context/UserAgentContext';
import {useCurrentSlug} from '@/hooks/useCurrentSlug';
import {useEnhanced} from '@/hooks/useEnhanced';
import useIsPDPSubpage from '@/hooks/useIsPDPSubpage';
import {useRouter} from '@/hooks/useRouter';
import {useShowFixedCTAMenu} from '@/hooks/useShowFixedCTAMenu';
import useUpdateEffect from '@/hooks/useUpdateEffect';
import {getSectionTitleFromId} from '@/modules/ProductDetailPage/utils';
import {useAffordabilityDetails} from '@/queries/useAffordabilityDetails';
import {useLabel} from '@/queries/useLabel';
import {customEvent} from '@/utils/gtm';

export const PIVOT_PREFIX_ID = 'ss_pivot_';

const useSidebarPivotMenu = () => {
  const {setId, slug, section} = useCurrentSlug();
  const {data: product} = useLabel(setId);
  const muteScrolling = useRef(false);
  const {isFetching: isInitialLoading} = useEnhanced(setId);
  const showNewAffordability = useFlag(FLAGS.AFFORDABILITY_PDP_SECTION);
  const {isPending: isPendingAffordability} = useAffordabilityDetails(setId, {
    enabled: !!setId && showNewAffordability,
  });
  const isPDPSubpage = useIsPDPSubpage();
  const {isMobileOrTablet} = useUserAgentContext();
  // 56 and 64 correspond to the size in px of the Navbar in mobile and desktop respectively
  const router = useRouter();
  const showCTAFixedMenu = useShowFixedCTAMenu();
  const pivotItemsPositions = useRef(null);
  const refFireCEvent = useRef(false);
  const [cookies] = useCookies(['hubspotutk', SPLIT_USER_COOKIE]);
  const {filteredAnchors, getSectionTitle} = useFilteredAnchors(setId);
  const {activeItem, setActiveItem, checkIfPdpHeaderV1IsVisible} =
    useScrollHandler(setId, section);

  useEffect(() => {
    // Get the anchor value from the URL
    const anchor = router?.asPath?.split('#')[1];

    if (
      anchor &&
      product &&
      !isInitialLoading &&
      (anchor !== ENHANCED_SECTION_IDS.financialAssistance ||
        (showNewAffordability && !isPendingAffordability))
    ) {
      muteScrolling.current = true;
      setTimeout(() => {
        document.getElementById(`ss_pivot_${anchor}`)?.click();
        setTimeout(() => {
          muteScrolling.current = false;
        }, 1001); //As the animation takes 1000ms
      }, 1000);
    }
  }, [
    isInitialLoading,
    isPendingAffordability,
    product,
    router?.asPath,
    showNewAffordability,
  ]);

  useUpdateEffect(() => {
    if (activeItem && !isMobileOrTablet && !isPDPSubpage) {
      window.history.replaceState(window.history.state, '', `#${activeItem}`);
    }
  }, [activeItem]);

  const handleItemClick = useCallback(
    (anchor: string, isTrusted: boolean) => {
      if (showCTAFixedMenu) {
        checkIfPdpHeaderV1IsVisible();
      }

      if (isMobileOrTablet) {
        window.history.replaceState(window.history.state, '', `#${anchor}`);
      }
      muteScrolling.current = true;
      setActiveItem(anchor);
      if (refFireCEvent.current && isTrusted) {
        customEvent<DlvNavigate>(CEVENT_NAVIGATE, {
          pageSection: getSectionTitleFromId(anchor),
          userKey: cookies[SPLIT_USER_COOKIE],
        });
      }
      setTimeout(() => {
        refFireCEvent.current = true;
        muteScrolling.current = false;
      }, 1001); //As the animation takes 1000ms

      if (isPDPSubpage && anchor !== SUBPAGES_SECTION_IDS[section]) {
        router.push(`/therapies/${slug}#${anchor}`);
      }
    },
    [
      showCTAFixedMenu,
      isMobileOrTablet,
      isPDPSubpage,
      router,
      section,
      slug,
      cookies,
      checkIfPdpHeaderV1IsVisible,
      setActiveItem,
    ],
  );

  //get pivot items positions for pivot horizontal scrolling
  useEffect(() => {
    if (!pivotItemsPositions.current) {
      let initialPositions: any = {};
      filteredAnchors.forEach((section) => {
        const pivotItemElement = document.querySelector(
          `#${PIVOT_PREFIX_ID}${section}`,
        );
        if (pivotItemElement) {
          initialPositions[section] =
            pivotItemElement?.getBoundingClientRect().left;
        }
      });
      pivotItemsPositions.current = initialPositions;
    }
  }, [filteredAnchors]);

  //horizontal scroll into view selected pivot item
  useEffect(() => {
    const anchorElem = document?.querySelector(
      `#${PIVOT_PREFIX_ID}${activeItem}`,
    );

    if (anchorElem && pivotItemsPositions?.current) {
      const pivotEl = document.querySelector('#pivotMenu')!;
      const initialLeftPosition =
        pivotItemsPositions?.current[filteredAnchors[0]];
      pivotEl.scrollTo({
        left: pivotItemsPositions?.current[activeItem] - initialLeftPosition,
        behavior: 'smooth',
      });
    }
  }, [activeItem, filteredAnchors]);

  return {
    filteredAnchors,
    activeItem,
    showCTAFixedMenu,
    handleItemClick,
    getSectionTitle,
  };
};

export default useSidebarPivotMenu;
