import clsx from 'clsx';
import dynamic from 'next/dynamic';
import {FC, useEffect, useState, memo} from 'react';
import {Footer, CustomNavbar as Navbar} from '@/components';
import SidebarPivotMenu from '@/components/SidebarPivotMenu';
import {NAVBAR_ID} from '@/constants';
import {
  TERMS_AND_CONDITIONS,
  COVERAGE_FOR_CONSTELLATION,
  COVERAGE_ON_PDP,
  SHOW_V2_PPT_DESIGN,
} from '@/constants/flags';
import {useFlag, CoverageRestrictionsProvider} from '@/context';
import {useUserAgentContext} from '@/context/UserAgentContext';
import {useCurrentSlug, useIsPDP, useIsOtc, useRenderInIFrame} from '@/hooks';
import {useSectionsStatus} from '@/hooks/showSections/useSectionsStatus';
import useIsPDPSubpage from '@/hooks/useIsPDPSubpage';
import {useCurrentPerson, useLabel} from '@/queries';

const Disclaimer = dynamic(() => import('@/components/Disclaimer'), {
  ssr: false,
});

const TermsAndConditions = dynamic(
  () => import('@/modules/HomePage/TermsAndConditions'),
  {
    ssr: false,
  },
);

interface PdpLayoutProps {
  children: React.ReactNode;
}

const PdpLayout: FC<PdpLayoutProps> = ({children}) => {
  const {isMobile, isTablet} = useUserAgentContext();
  const isPDP = useIsPDP();
  const isPDPSubpage = useIsPDPSubpage();
  const {setId, isConstellationDrug} = useCurrentSlug();
  const {data: product} = useLabel(setId);
  const [isOpenSidebarPivotMenu, setIsOpenSidebarPivotMenu] = useState(false);

  const showPivot = (isPDP || isPDPSubpage) && setId && product;
  const isOtc = useIsOtc();
  const enableCoverageForConstellationOnly = useFlag(
    COVERAGE_FOR_CONSTELLATION,
  );

  const showCoverageOnPdp =
    useFlag(COVERAGE_ON_PDP) &&
    (enableCoverageForConstellationOnly ? isConstellationDrug : true);
  const termsAndConditionsEnabled = useFlag(TERMS_AND_CONDITIONS);
  const {data: user} = useCurrentPerson();
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const showNewDesign = useFlag(SHOW_V2_PPT_DESIGN);
  const {showButtonAI} = useSectionsStatus();

  useEffect(() => {
    if (termsAndConditionsEnabled && user)
      setShowTermsAndConditions(user.shouldNeedAcceptTsCs);
  }, [user, termsAndConditionsEnabled]);

  const [stickyNavbar, setStickyNavbar] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const pdpIsEmbedded = useRenderInIFrame();

  const shouldShowPivotMenu = !pdpIsEmbedded && showPivot && !isOtc;

  useEffect(() => {
    if (isMobile || isTablet) {
      window.onscroll = function () {
        if (window.scrollY === 0) {
          setStickyNavbar(true);
        } else {
          setStickyNavbar(false);
        }
      };
    }
  }, [isMobile, isTablet, stickyNavbar]);

  const closeTermsAndConditions = () => {
    setShowTermsAndConditions(false);
  };

  const renderContent = () => (
    <div className='flex min-h-screen flex-col'>
      {showTermsAndConditions && (
        <TermsAndConditions handleClose={closeTermsAndConditions} />
      )}
      {!pdpIsEmbedded ? (
        <div
          id={NAVBAR_ID}
          className={clsx([
            'relative w-full bg-white',
            isMobile || isTablet ? 'z-50' : 'z-max',
            {
              'sticky top-0 z-max': stickyNavbar,
            },
          ])}>
          <Disclaimer
            showDisclaimer={showDisclaimer}
            setShowDisclaimer={setShowDisclaimer}
          />
          <Navbar
            isSidebarOpen={isOpenSidebarPivotMenu}
            setSidebarOpen={(value: boolean) =>
              setIsOpenSidebarPivotMenu(value)
            }
          />
        </div>
      ) : null}

      <div className='flex flex-1 flex-col lg:flex-row'>
        {shouldShowPivotMenu ? (
          <SidebarPivotMenu isOpen={isOpenSidebarPivotMenu} />
        ) : null}
        <div
          className={clsx(
            'flex flex-1 flex-col',
            showNewDesign ? 'bg-neutral-lighter-alt' : 'bg-white',
          )}
          id='layout-content'>
          <div
            className={clsx(
              'flex flex-1 flex-col',
              showNewDesign ? 'bg-neutral-lighter-alt' : 'bg-white',
            )}
            id='layout-content'>
            <div className='min-h-page-content flex-1'>{children}</div>
            {!pdpIsEmbedded && !isOtc ? (
              <div className='w-full'>
                <Footer />
                <div
                  className={clsx('bg-neutral-dark md:hidden', {
                    'min-h-[87px]': showButtonAI && isMobile,
                  })}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );

  return showCoverageOnPdp ? (
    <CoverageRestrictionsProvider>
      {renderContent()}
    </CoverageRestrictionsProvider>
  ) : (
    renderContent()
  );
};

export default memo(PdpLayout);
