import {Button} from '@prescriberpoint/ui';
import {ClassValue} from 'clsx';
import {FC} from 'react';
import {CEVENT_DDI_CHECK, DlvDDICheck} from '@/constants';
import {useCurrentSlug, useDlvDrugInfo} from '@/hooks';
import {useRouter} from '@/hooks/useRouter';
import {customEvent} from '@/utils/gtm';

interface IDDIButtonProps {
  id: string;
  className?: ClassValue;
  variant?: 'primary' | 'secondary';
  withIcon?: boolean;
}

const DDIButton: FC<IDDIButtonProps> = (props) => {
  const {slug} = useCurrentSlug();
  const {drugName, drugSpecialties, pharmaCompany} = useDlvDrugInfo();
  const router = useRouter();

  const handleClick = () => {
    router.push('/therapies/' + slug + '/interactions');

    customEvent<DlvDDICheck>(CEVENT_DDI_CHECK, {
      drugName,
      pharmaCompany,
      drugSpecialties,
    });
  };

  return (
    <Button
      id={props.id}
      size='lg'
      variant={props.variant}
      stretched
      onClick={handleClick}
      className={props.className}
      iconLeft={props.withIcon ? 'InteractionsNegative' : undefined}>
      Check Drug Interactions
    </Button>
  );
};

export default DDIButton;
