import {Button, toSnakeCase, Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {format, subDays} from 'date-fns';
import {FC} from 'react';
import {Link} from 'react-scroll';
import OverviewLink from './OverviewLink';
import useSidebarPivotMenu from './useSidebarPivotMenu';
import {
  SIDEBAR_PIVOT_OFFSET_SCROLL,
  OFFSET_SCROLL_WITH_PDP_HEADER_V2,
} from '@/constants/pdpPage';

export const PIVOT_PREFIX_ID = 'ss_pivot_';

interface ISidebarPivotMenuProps {
  isOpen: boolean;
}

const SidebarPivotMenu: FC<ISidebarPivotMenuProps> = ({isOpen}) => {
  const {
    activeItem,
    filteredAnchors,
    showCTAFixedMenu,
    handleItemClick,
    getSectionTitle,
  } = useSidebarPivotMenu();

  const yesterdaysDate = format(subDays(new Date(), 1), 'MMM, dd yyyy');

  return (
    <>
      <div
        className={clsx('lg:hidden', {
          'background fixed z-30 h-full w-screen bg-black opacity-50': isOpen,
        })}
      />
      <div
        className={clsx(
          'fixed left-[-251px] z-30 h-full w-[251px] transform bg-white transition-transform lg:static lg:w-[248px] lg:transform-none',
          {
            'translate-x-0': !isOpen,
            'translate-x-full lg:translate-x-0': isOpen,
          },
        )}>
        <div
          className={clsx('flex flex-col space-y-6 p-6 lg:fixed')}
          id='pivotMenu'
          data-testid='pivotMenu'>
          <Text as='overline-xs' size='xs' className='text-blue-500'>
            {`Last Update: ${yesterdaysDate}`}
          </Text>
          <div className='flex flex-col space-y-3'>
            <OverviewLink activeItem={!activeItem} />
            {filteredAnchors.map((item) => (
              <Link
                key={item}
                to={item}
                smooth='easeInOutQuart'
                duration={1000}
                role='tab'
                offset={
                  showCTAFixedMenu
                    ? -OFFSET_SCROLL_WITH_PDP_HEADER_V2
                    : -SIDEBAR_PIVOT_OFFSET_SCROLL
                }
                id={`${PIVOT_PREFIX_ID}${toSnakeCase(item)}`}
                // ignore ts since signature of onClick on react-scroll lib dont receive
                // params but it actually does receive the event object
                // @ts-ignore
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                  handleItemClick(item, e.isTrusted)
                }>
                <Button
                  id={`${PIVOT_PREFIX_ID}${toSnakeCase(item)}-button`}
                  title={getSectionTitle(item)}
                  variant={activeItem === item ? 'alternative' : 'text'}
                  stretched
                  className={clsx(
                    'group justify-start capitalize hover:bg-green-50 hover:!text-theme-primary',
                    {'bg-green-50 !text-theme-primary': activeItem === item},
                  )}
                  classNameText={clsx(
                    'capitalize group-hover:!text-theme-primary',
                    {
                      '!text-theme-primary': activeItem === item,
                    },
                  )}>
                  {getSectionTitle(item)}
                </Button>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarPivotMenu;
