'use client';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import {FC, useState} from 'react';
import Navbar from '@/components/CustomNavbar';
import Disclaimer from '@/components/Disclaimer';
import {NAVBAR_ID} from '@/constants';
import {SHOW_V2_PPT_DESIGN} from '@/constants/flags';
import {useFlag, useMobileContentContext} from '@/context';

const MobileContentProvider = dynamic(() =>
  import('@/context/MobileContentProvider').then(
    (m) => m.MobileContentProvider,
  ),
);

const Footer = dynamic(() => import('@/components/Footer'));

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: FC<MainLayoutProps> = ({children}) => {
  const {isMenuOpen} = useMobileContentContext();
  const showNewDesign = useFlag(SHOW_V2_PPT_DESIGN);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  return (
    <div
      className={clsx('relative flex h-fit min-h-screen flex-col', {
        'overflow-hidden': isMenuOpen,
      })}>
      <div
        id={NAVBAR_ID}
        className='sticky top-0 z-50 w-full bg-white xs:z-max'>
        <Disclaimer
          showDisclaimer={showDisclaimer}
          setShowDisclaimer={setShowDisclaimer}
        />
        <Navbar />
      </div>

      <div
        className={clsx(
          'flex-1',
          showNewDesign ? 'bg-neutral-lighter-alt' : 'bg-white',
        )}>
        {children}
      </div>
      <Footer />
    </div>
  );
};

const MainLayoutWrapper: FC<MainLayoutProps> = ({children}) => (
  <MobileContentProvider>
    <MainLayout>{children}</MainLayout>
  </MobileContentProvider>
);

export default MainLayoutWrapper;
