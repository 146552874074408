import {
  FormInput,
  Button,
  Text,
  IFormInput,
  GenericInput,
} from '@prescriberpoint/ui';
import {FC, FormEvent, useState, useMemo, useEffect} from 'react';
import StepFormItem from './StepFormItem';

export interface IStep {
  number: number;
  label: string;
  inputs: IFormInput[];
}

export interface IStepForm {
  stepsForm: IStep[];
  onSubmit?: (_data: any) => Promise<void>;
  submitError?: boolean;
  submitErrorMsg?: string;
  startingStep?: number;
  submitBtnText?: string;
  disclaimerText?: string;
  disclaimerAcceptedByDefault?: boolean;
}

const StepForm: FC<IStepForm> = ({
  stepsForm,
  onSubmit,
  submitError,
  submitErrorMsg = 'Something went wrong',
  startingStep = 1,
  submitBtnText = 'Submit',
  disclaimerText,
  disclaimerAcceptedByDefault = true,
}) => {
  const [currentStep, setCurrentStep] = useState(startingStep);

  const [formValues, setFormValues] = useState(new Map());
  const [formData, setFormData] = useState({});
  const [formValid, setFormValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(
    disclaimerAcceptedByDefault,
  );

  useEffect(
    () => {
      const formValuesTemp = formValues;
      stepsForm.forEach((step) => {
        step.inputs.forEach((input) => {
          formValuesTemp.set(input.id, {
            value: input.defaultValue,
            valid: false,
            required: input.required,
            stepNumber: step.number,
          });
        });
      });
      setFormValues(formValuesTemp);
    },
    // eslint-disable-next-line
    [],
  );

  const isFormValid = useMemo(() => {
    const disclaimerValid = disclaimerText ? disclaimerAccepted : true;
    return disclaimerValid && formValid;
  }, [disclaimerText, formValid, disclaimerAccepted]);

  const updateFormValue = (
    stepNumber: number,
    stepIndex: number,
    id: string,
    value: string,
    valid: boolean,
    required?: boolean,
  ) => {
    const formValuesTemp = formValues.set(id, {
      value,
      valid,
      required,
      stepNumber,
    });
    setFormValues(formValuesTemp);
    setFormData((prev) => ({...prev, [id]: value}));
    const arrayValues = Array.from(formValuesTemp.values());
    setFormValid(
      arrayValues.every((item) =>
        item.required ? item.valid : item.value === '',
      ),
    );

    // Check if we are in the current step and the current step is not the last
    if (stepNumber === currentStep && stepIndex < stepsForm?.length - 1) {
      const requiredFields = stepsForm[stepIndex].inputs.filter(
        (input) => input.required === true,
      )?.length;
      const stepValues = arrayValues.filter(
        ({stepNumber}) => stepNumber === currentStep,
      );
      const stepValid = stepValues.every((item) =>
        item.required ? item.valid : item.value == '',
      );
      if (stepValid && requiredFields <= stepValues?.length) {
        setCurrentStep((prev) => prev + 1);
      }
    }
  };

  const onSubmitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isFormValid) {
      return;
    }
    try {
      setErrorMessage('');
      setIsSubmitting(true);
      if (onSubmit) {
        await onSubmit(formData);
      }
    } catch (e) {
      setErrorMessage(submitErrorMsg);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form className='flex w-full flex-col' onSubmit={onSubmitForm}>
      <div className='flex flex-col'>
        {stepsForm.map((step, stepIndex) => (
          <StepFormItem
            key={`step-form-step-${step.number}`}
            stepNumber={step.number}
            disabled={currentStep < step.number}
            disabledLabel={step.label}>
            {step.inputs.map((input) => (
              <GenericInput
                key={`step-form-step-item-${input.id}`}
                formInput={input}
                setFormValue={(id, value, valid, required) =>
                  updateFormValue(
                    step.number,
                    stepIndex,
                    id,
                    value,
                    valid,
                    required,
                  )
                }
              />
            ))}
          </StepFormItem>
        ))}
      </div>
      <Button
        id='submit_step_form'
        type='submit'
        testId='submit_step_form'
        className='mt-[70px] md:mt-6'
        size='xl'
        stretched
        disabled={
          !isFormValid || isSubmitting || currentStep < stepsForm?.length
        }>
        {submitBtnText}
      </Button>
      {submitError || errorMessage ? (
        <Text as='body-xs' weight='bold' variant='error' tag='div'>
          {submitErrorMsg || errorMessage}
        </Text>
      ) : null}
      {disclaimerText ? (
        <FormInput.Check
          className='mt-6'
          id='step_form_disclaimer'
          checked={disclaimerAccepted}
          labelClassNames='text-sm font-semibold font-mulish'
          name='step_form_disclaimer'
          onChange={() => setDisclaimerAccepted(!disclaimerAccepted)}
          label={disclaimerText}
        />
      ) : null}
    </form>
  );
};

export default StepForm;
